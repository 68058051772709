import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import jQuery from "jquery"
import $ from "jquery"
/*import Swiper from "swiper"*/
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';


Swiper.use([Navigation, Pagination, Autoplay]);
class IndexPage extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      const mySwiper = new Swiper(
        ".swiper-2072dca2-a0d9-4756-b445-ad35a02f039a.swiper-container",
        {
          navigation: {
            nextEl: ".button-next",
            prevEl: ".button-prev",
          },
          loop: !$("body").hasClass("page-editor"),
          speed: 1000,
          slidesPerView: "auto",
          centeredSlides: true,
          spaceBetween: "10%",
          setWrapperSize: true,
          longSwipes: true,
          shortSwipes: true,
          threshold: 30,
          longSwipesRatio: 0.1,
          touchRatio: 0.5,
          pagination: {
            el: "",
            clickable: true,
          },
          autoplay: false,
        }
      )
    },100);
    setTimeout(() => {
      const defaultOptions = new Swiper(
        ".swiper-38585daf-c607-45ef-bd4e-d0a308a0ee67.swiper-container",
        {
          loop: true,
          speed: 1000,
          slidesPerView: 1,
          centeredSlides: true,
          longSwipes: true,
          shortSwipes: true,
          longSwipesRatio: 0.1,
          threshold: 30,
          touchRatio: 0.5,
          navigation: {
            nextEl: ".button-next",
            prevEl: ".button-prev",
          },
          pagination: {
            el: "",
            clickable: true,
          },
          autoplay:false,
          setWrapperSize: false,
          spaceBetween: 0,
          slidesPerGroup: 1,
          parallax: true,
          mousewheelControl: false,
          mousewheelReleaseOnEdges: true,
          direction: "horizontal",
          breakpoints: {},
        }
      )
    },100);
  }
  render() {
    return (
      <Layout metaTitle="Thick Cut Bacon the Wright Way"
        metaDescription="Wright® Brand Bacon - Hand trimmed, thick cut and real wood smoked. Rich in both tradition and flavor since 1922. Visit our website for recipes and product info.">
        <div class="swiper-container swiper-38585daf-c607-45ef-bd4e-d0a308a0ee67 home-carousel">
          <ul class="swiper-wrapper">     
            <li class="swiper-slide  ">
              <a
                href="/products/"
                class="carousel-image-link"
              >
                <div class="caption">
                  <span class="content">
                    <h1 className="text-center">Real Wood Smoked</h1>
                  </span>
                </div>
                <img
                  src={
                    "/tysonscore3.azureedge.net/assets/media/wrightbrand/images/wright-brand-bacon-homepage-banner-1920x500.png"
                  }
                  alt="Wright Brand Bacon"
                />
                <a
                  href="/products/"
                  class="btn btn-default"
                >
                  Explore
                </a>{" "}
              </a>
            </li>
            <li class="swiper-slide  ">
              <a href="/products/#writeareview" class="carousel-image-link">
                <div class="caption">
                  <span class="content">
                    <h1 className="text-center">
                      Tell us about our thick-cut, hand-trimmed bacon.&nbsp;
                    </h1>
                  </span>
                </div>
                <img
                  src={
                    "/tysonscore3.azureedge.net/assets/media/wrightbrand/wrightbrand_review_HP_hero_1920x500.png"
                  }
                  alt="Write a Review"
                />
                <a href="/products/#writeareview" class="btn btn-default">
                  Write A Review
                </a>{" "}
              </a>
            </li>
          </ul>

          <div className="button-prev">
            <img
              class="hidden-lg hidden-md hidden-sm"
              src={
                "tysonscore3.azureedge.net/assets/media/wrightbrand/images/icons/elipseleftarrow.png"
              }
              alt=""
            />
            <img
              class="hidden-xs"
              src={
                "tysonscore3.azureedge.net/assets/media/wrightbrand/images/icons/leftarrow.png"
              }
              alt=""
            />
          </div>
          <div className="button-next">
            <img
              class="hidden-lg hidden-md hidden-sm"
              src={
                "tysonscore3.azureedge.net/assets/media/wrightbrand/images/icons/elipserightarrow.png"
              }
              alt=""
            />
            <img
              class="hidden-xs"
              src={
                "tysonscore3.azureedge.net/assets/media/wrightbrand/images/icons/rightarrow.png"
              }
              alt=""
            />
          </div>
        </div>

        <article class="page-section no-background ">
          <div class="image"></div>
          <div class="article-shadow"></div>

          <div class="content">
            <p>
                Hand trimmed, thick cut, and real wood smoked.
                The Wright Way to make bacon started in 1922.
            </p>{" "}
          </div>
        </article>

        <article class="page-section carousel">
          <div class="image" style={{ height: "680px" }}>
            <img
              style={{ height: "680px" }}
              src={
                "tysonscore3.azureedge.net/assets/media/wrightbrand/images/backgrounds/productcarouselbackgroud.jpg"
              }
              alt="ProductCarouselBackground"
            />
          </div>
          <div class="article-shadow"></div>
          <div class="content">
            <div class="swiper-container swiper-2072dca2-a0d9-4756-b445-ad35a02f039a product-rotator ">
              <ul class="swiper-wrapper">
                <li class="swiper-slide">
                  <div class="caption">Applewood Smoked Bacon</div>
                  <a href="/products/applewood-smoked/">
                    <img
                      src={
                        "/tysonscore3.azureedge.net/assets/media/wrightbrand/images/carousels/applewoodbacon.png"
                      }
                      alt="Applewood Smoked Bacon"
                    />
                  </a>
                </li>
                <li class="swiper-slide">
                  <div class="caption">Double Smoked Bacon</div>
                  <a href="/products/double-smoked-bacon/">
                    <img
                      src={
                        "/tysonscore3.azureedge.net/assets/media/wrightbrand/images/products/doublesmokedbig.png"
                      }
                      alt="Double Smoked Bacon"
                    />
                  </a>
                </li>
                <li class="swiper-slide">
                  <div class="caption">Hickory Smoked Bacon</div>
                  <a href="/products/hickory-smoked/">
                    <img
                      src={
                        "/tysonscore3.azureedge.net/assets/media/wrightbrand/images/products/updated-hickory_smoked_bacon.png"
                      }
                      alt="Hickory Smoked"
                    />
                  </a>
                </li>
                <li class="swiper-slide">
                  <div class="caption">Maple Flavored Bacon</div>
                  <a href="/products/maple-flavored/">
                    <img
                      src={
                        "/tysonscore3.azureedge.net/assets/media/wrightbrand/images/carousels/maplebacon.png"
                      }
                      alt="Maple Flavored Bacon"
                    />
                  </a>
                </li>
              </ul>
              <div class="button-prev">
                <img
                  class="hidden-lg hidden-md hidden-sm"
                  src={
                    "tysonscore3.azureedge.net/assets/media/wrightbrand/images/icons/elipseleftarrow.png"
                  }
                  alt=""
                />
                <img
                  class="hidden-xs"
                  src={
                    "tysonscore3.azureedge.net/assets/media/wrightbrand/images/icons/leftarrow.png"
                  }
                  alt=""
                />
              </div>
              <div class="button-next">
                <img
                  class="hidden-lg hidden-md hidden-sm"
                  src={
                    "tysonscore3.azureedge.net/assets/media/wrightbrand/images/icons/elipserightarrow.png"
                  }
                  alt=""
                />
                <img
                  class="hidden-xs"
                  src={
                    "tysonscore3.azureedge.net/assets/media/wrightbrand/images/icons/rightarrow.png"
                  }
                  alt=""
                />
              </div>
            </div>

            <div class="generic-btn arrow">
              <a href="/locator/" title="Locate Bacon">
                Locate Bacon
              </a>
            </div>
          </div>
        </article>

        <article class="page-section ">
          <div class="image">
            <img
              src={
                "/tysonscore3.azureedge.net/assets/media/wrightbrand/images/backgrounds/wrightbrand_howwemakethebacon.jpg"
              }
              alt="How We Make Bacon"
            />
          </div>
          <div class="article-shadow"></div>

          <div class="content">
            <h2 class="title">How we make the bacon, makes the bacon.</h2>
            <p>
                Hand selected. Hand trimmed. Our 100-year-old proprietary curing recipe brings forth uncompromising flavor.
                A thick, smoky character you can count on in each and every slice.
            </p>{" "}
          </div>
        </article>

        <article class="page-section with-placeholder no-background callout-container">
          <div class="image"></div>
          <div class="article-shadow"></div>
          <div class="content">
            <div class="callout  col-xs-12 col-md-6 col-sm-6">
              <div class="image-wrapper">
                <img
                  src={
                    "/tysonscore3.azureedge.net/assets/media/wrightbrand/images/callouts/wrightbrand_prepare_HP_584x348.png"
                  }
                  alt="how-to"
                />
              </div>
              <div class="content-wrapper">
                <h3>Prepare</h3>
                <p>Bacon The Wright Way&trade;</p>
                <div class="generic-btn arrow hidden-lg hidden-md hidden-sm">
                  <a href="/how-to/how-to-oven-bake-bacon/">View All</a>
                </div>
              </div>
              <a
                class="callout-link hidden-xs"
                href="/how-to/how-to-oven-bake-bacon/"
                aria-label="Prepare"
              ></a>
              <div class="callout-overlay"></div>
            </div>

            <div class="callout  col-xs-12 col-md-6 col-sm-6">
              <div class="image-wrapper">
                <img
                  src={
                    "/tysonscore3.azureedge.net/assets/media/wrightbrand/images/callouts/wrightbrand_recipes_HP_584x348.png"
                  }
                  alt="recipes"
                />
              </div>
              <div class="content-wrapper">
                <h3>Recipes</h3>
                <p>From Wright Brand Bacon</p>
                <div class="generic-btn arrow hidden-lg hidden-md hidden-sm">
                  <a href="/recipes/">View All</a>
                </div>
              </div>
              <a
                class="callout-link hidden-xs"
                href="/recipes/"
                aria-label="Recipes"
              ></a>
              <div class="callout-overlay"></div>
            </div>
          </div>
        </article>

        <article class="page-section ">
          <div class="image">
            <img
              src={
                "/tysonscore3.azureedge.net/assets/media/wrightbrand/images/backgrounds/homehistory.jpg"
              }
              alt="Wright Brand History"
            />
          </div>
          <div class="article-shadow"></div>

          <div class="content">
            <h2 class="title">
              We do things a lot like your great grandfather did.
            </h2>
            <p>
              Rich in both tradition and flavor, the bold taste of Wright Brand
              bacon has been savored since Roy Wright and Fay Eggleston
              handcrafted their first batch in 1922. It&rsquo;s a history of
              doing things a certain way, and one we don&rsquo;t plan on
              changing.{" "}
            </p>{" "}
          </div>

          <img
            class="brand-badge"
            src="/tysonscore3.azureedge.net/assets/media/0F0B8F52F0504F13BD64B61EFB8A87C2.png"
            alt="Wright Brand"
          />
        </article>
          {/*
        <article class="page-section no-background ">
          <div class="image"></div>
          <div class="article-shadow"></div>

          <div class="content">
            <div class="container">
              <div class="video-container">
                <div class="col-md-6">
                  <div class="youtube-embed">
                    <div class="embed-responsive embed-responsive-16by9">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/qLQiQmx5RHs"
                        frameborder="0"
                        title="Wright Brand Bacon Video"
                      ></iframe>
                    </div>
                  </div>
                </div>
                <p class="video-copy col-md-6"></p>
                <h2 className="mt-0 text-left">
                  <span>Strong and Silent</span>
                </h2>

                <p className="description text-left">
                  Our thick-cut bacon is a point of pride. Here&rsquo;s a little
                  insight into why we keep it thick. #BaconTheWrightWay
                </p>
              </div>
            </div>{" "}
          </div>
        </article>
            */}
        <article class="locator-callout">
          <div class="quick-form-container">
            <div className="product-locator-search">
              <h2>Find Our Products</h2>
              <form data-url="/locator/" action="/locator/">
                <input
                  value="ANY_ANY"
                  id="PROD"
                  name="PROD"
                  type="hidden"
                  value="ANY_ANY"
                />
                <div className="input-wrapper">
                  <input
                    data-val="true"
                    data-val-regex="Please enter a 5-digit ZIP code"
                    data-val-regex-pattern="^\d{5}"
                    data-val-required="Please enter a 5-digit ZIP code"
                    id="ZIP"
                    maxLength="5"
                    name="ZIP"
                    placeholder="Enter Zip"
                    type="tel"
                    defaultValue=""
                  />
                  <input type="submit" value="Locate Bacon"></input>
                  <span
                    className="field-validation-valid"
                    data-valmsg-for="ZIP"
                    data-valmsg-replace="true"
                  ></span>
                </div>
              </form>
            </div>
          </div>
        </article>
      </Layout>
    )
  }
}

export default IndexPage
